import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingPage from '../views/LandingPage.vue'
import Home from '../views/Home.vue'
import Category from '../views/Category.vue'
import Search from '../views/Search.vue'
import StoreLocator from '../views/StoreLocator.vue'
import Cart from '../views/Cart.vue'
import AllCart from '../views/AllCart.vue'
import FoodCart from '../views/FoodCart.vue'
import Checkout from '../views/Checkout.vue'
import FoodCheckout from '../views/FoodCheckout.vue'
import CheckoutBoth from '../views/CheckoutBoth.vue'
import OrderConfirmation from '../views/OrderConfirmation.vue'
import GiftCard from '../views/GiftCard.vue'
import Contact from '../views/Contact.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import ResetPassword from '../views/Reset.vue'
import Terms from '../views/Terms.vue'
import Privacy from '../views/Privacy.vue'
import About from '../views/About.vue'
import Account from '../views/Account.vue'
import Address from '../views/AddressBook.vue'
import MyOrders from '../views/Orders.vue'
import OrderView from '../views/OrderView.vue'
import ProductView from '../views/Product.vue'
import PreparedFoodProductView from '../views/FoodProductView.vue'
import FoodProductView from '../views/FoodProduct.vue'
import NotFound from '../components/404.vue';
import Wallet from '../views/Wallet.vue';
import WalletNew from '../views/WalletNew.vue';
import WalletPin from '../views/SetWalletPin.vue';
import FundWallet from '../views/FundWallet.vue';
import Promotions from "@/views/Promotions";
//import LoversDelight from "@/views/LoversDelight.vue";
//import ChristmasPromo from "@/views/ChristmasPromo.vue";
//import BlackFriday from '../views/BlackFriday.vue';
//import NewBlackFriday from '../views/NewBlackFriday.vue';
import Hampers from '../views/Hampers.vue';
import PreparedFood from '../views/PreparedFood.vue';
import ValentineForm from '../views/ValentineForm.vue';
import ReferralAgent from '../views/ReferralAgent.vue';
import ReferredCustomers from '../views/ReferredCustomers.vue';


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },


    {
        path: '/promotions/:promotion',
        name: 'Promotions',
        component: Promotions
    },
    {
        path: '/hampers-&-giftcards',
        name: 'Hampers',
        component: Hampers
    },
    {
        path: '/preparedfood',
        name: 'PreparedFood',
        component: PreparedFood
    },

    // {
    //  path: '/black-friday',
    //name: 'NewBlackFriday',
    //component:NewBlackFriday
    //},
    // {
    //path: '/christmas-promo',
    //name: 'ChristmasPromo',
    //  component:ChristmasPromo
    //},

    //{
    // path: '/lovers-delight',
    //name: 'LoversDelight',
    // component:LoversDelight
    // },


    {
        path: '/refer',
        name: 'ReferralAgent',
        component: ReferralAgent
    },
    {
        path: '/referred-customers',
        name: 'ReferredCustomers',
        component: ReferredCustomers
    },
    {
        path: '/valentine/story',
        name: 'ValentineForm',
        component: ValentineForm
    },
    {
        path: '/category/:cat',
        name: 'Category',
        component: Category
    },
    
    {
        path: '/category/:cat/:searchQuery',
        name: 'Category',
        component: Category
    },
    {
        path: '/search/:search',
        name: 'Search',
        component: Search
    },
    {
        path: '/storelocator',
        name: 'StoreLocator',
        component: StoreLocator
    },
    {
        path: '/cart',
        name: 'Cart',
        component: Cart
    },
    {
        path: '/allcart',
        name: 'AllCart',
        component: AllCart
    },
    {
        path: '/foodcart',
        name: 'FoodCart',
        component: FoodCart
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout
    },
    {
        path: '/checkoutboth',
        name: 'CheckoutBoth',
        component: CheckoutBoth
    },

    {
        path: '/foodcheckout',
        name: 'FoodCheckout',
        component: FoodCheckout
    },
    {
        path: '/confirm',
        name: 'OrderConfirmation',
        component: OrderConfirmation
    },
    {
        path: '/giftcard',
        name: 'GiftCard',
        redirect: '/home'
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    // {
    //     path: '/password/reset/:key',
    //     name: 'ResetPassword',
    //     component: ResetPassword
    // },
    {
        path: '/reset/:key',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/account',
        name: 'MyAccount',
        component: Account
    },
    {
        path: '/addressbook',
        name: 'AddressBook',
        component: Address
    },
    {
        path: '/orders',
        name: 'MyOrders',
        component: MyOrders
    },
    {
        path: '/wallet',
        name: 'Wallet',
        component: Wallet
    },

    {
        path: '/newwallet',
        name: 'WalletNew',
        component: WalletNew
    },
    {
        path: '/fundwallet',
        name: 'FundWallet',
        component: FundWallet
    },
    {
        path: '/walletpin',
        name: 'WalletPin',
        component: WalletPin
    },
    {
        path: '/orderview/:id',
        name: 'MyOrder',
        component: OrderView
    },
    /* {
         path: '/product/:store/:category/:name',
         name: 'FoodProductView',
         component: FoodProductView
     },

    {
        path: '/foodproduct/:store/:category/:name',
        name: 'PreparedFoodProductView',
        component: FoodProductView
    },*/

    {
        path: '/product/:store/:category/:name',
        name: 'ProductView',
        component: ProductView
    },
    { path: '*', component: NotFound },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router