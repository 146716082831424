<template>
 <div class="col-sm-3">
   <div id="options">
     <div class="opt "  :class="opt_active== 'orders'? 'active': ''" @click="$router.push('/orders')"   >
        <a href="/orders">My Orders</a> 
      </div>
      <div  class="opt "  :class="opt_active== 'account'? 'active': ''" @click="$router.push('/account')" >
         <a href="/account">My Account</a>
      </div>
      <div  class="opt " :class="opt_active== 'addressbook'? 'active':'' " @click="$router.push('/addressbook')" >
           <a href="/addressbook">Address Book</a>
       </div>
        <!-- <div  class="opt " :class="opt_active== 'wallet'?  'active':'' " @click="$router.push('/newwallet')" >
           <a href="/newwallet">Wallet</a>
       </div> -->
      
        
               

                    
                 </div>
                 </div>
              
              



</template>
<script>

export default {
  name: 'NewSidebar',
  props: {
    opt_active: String,
  },
  data(){
      return{
          optionsAreVisible: false,
      }
  },

  methods: {
    toggleOptions() {
        this.optionsAreVisible=!this.optionsAreVisible

    }

        }
}

</script>