import { render, staticRenderFns } from "./Disclaimer.vue?vue&type=template&id=0ea24824&scoped=true"
import script from "./Disclaimer.vue?vue&type=script&lang=js"
export * from "./Disclaimer.vue?vue&type=script&lang=js"
import style0 from "./Disclaimer.vue?vue&type=style&index=0&id=0ea24824&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ea24824",
  null
  
)

export default component.exports