<template>
  <div>
    <TopNav></TopNav>

    <div class="address page bg-white py-5">
      <div class="container">
        <div class="row">
          <NewSidebar v-bind:opt_active="opt_active" />

          <div class="col-sm-9 ">
            <div class="content top d-flex">
              <a href="/newwallet">
                <img
                  class="arr-left"
                  src="@/assets/img/back.png"
                  width="13"
                  height="12"
                />
              </a>
              <h4 class="title text-left">Fund Wallet</h4>
            </div>
            <v-container class="col-sm-9">
              <v-layout row wrap>
                <v-flex>
                  <div id="box">
                    <br />
                    <br />

                    <div class="">
                      <table class="table">
                        <tbody class="body2">
                          <tr>
                            <td>Balance:</td>
                            <td class="float-right ">
                              ₦{{
                                Number(user.wallet_balance).toLocaleString()
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Balance on Hold:</td>
                            <td class="float-right ">
                              ₦{{
                                user.balance_onHold == ""
                                  ? "0"
                                  : user.balanace_onHold
                              }}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th><b>Available Balance: </b></th>

                            <th class="float-right total">
                              ₦{{
                                Number(user.available_balance).toLocaleString()
                              }}
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>

                  <div id="boxx" class="checkout">
                    <div style="position:relative">
                      <!-- <div id="innbox" style="background-image: url('/img/card-design.png');height:160px;"> -->
                    </div>

                    <h4 class="text"><b>Fund Wallet</b></h4>

                    <br />

                    <div>
                      <h4 class="dep"><b> Amount[₦] </b></h4>
                      <p style="color:black ; font-size:10px">
                        <i>
                          <small>
                            Add funds to your MarketSquare e-wallet
                          </small></i
                        >
                      </p>
                      <input
                        style="margin-top:-16px"
                        type="number"
                        class="form-control col-12"
                        min="0"
                        onkeypress="return event.charCode >= 48"
                        placeholder=" "
                        v-model="transaction.balance"
                      />
                      <br />
                      <h4 class="dep "><b> Payment Options </b></h4>

                      <div id="summary">
                        <!-- <div class="row">
                        <div class="col-lg-6">
                          <button class="btn btnoptions form-control"  @click="paymethod($event, 'card')">
                        <div class="form-group form-check agree"
                    </div>
                      </div> -->
                        <div>
                          <div class="form-group form-check pay mt-3">
                            <input
                              id="voucherCheck"
                              type="checkbox"
                              class="form-check-input"
                              v-model="payment.voucher"
                              @change="paymethod($event, 'voucher')"
                            />
                            <label class="form-check-label" style="color:black"
                              >Pay with Giftcard
                              <br />
                              <span>Got a voucher or Gift card?</span>
                            </label>
                            <small
                              class="ml-2"
                              id="statusvoucher"
                              style="color:red;font-size:11px"
                            ></small>
                          </div>
                          <div v-if="payment.voucher" class="form-group">
                            <div class="form-row px-2">
                              <input
                                type="text"
                                class="form-control col-7"
                                placeholder="Enter serial no."
                                v-model="serialnumber"
                              />
                              <button
                                @click.prevent="verifyMethod('voucher')"
                                class="btn mt-1 ml-1 col-4"
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                          <div class="form-group form-check pay mt-3">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              @change="paymethod($event, 'card')"
                            />
                            <label class="form-check-label" style="color:black"
                              >Pay with - USSD, Bank Transfer or Card (Pay with
                              Flutterwave)
                              <small
                                class="ml-2"
                                id="balance"
                                style="color:red;font-size:11px"
                              ></small>
                              <br />
                              <span style="color:black"
                                >Pay with Flutterwave</span
                              >
                            </label>
                          </div>
                        </div>

                        <div class="form-group form-check agree">
                          <validation-provider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <p style="color:black ; font-size:10px">
                              <i>
                                By clicking on the complete payment button you
                                have accepted
                                <a
                                  href="/terms"
                                  style="color:#000066; font-weight:bold;"
                                  >terms and conditions</a
                                >
                                of MarketSquare
                              </i>
                            </p>
                            <span class="err_msg">{{ errors[0] }}</span>
                          </validation-provider>
                        </div>
                        <div class="col-lg-12">
                          <button
                            class="msq-button mt-3 btn form-control"
                            v-bind:disabled="!canPay"
                            v-bind:class="!canPay ? 'disabled' : ''"
                            type="submit"
                            @click.prevent="creditWallet"
                          >
                            COMPLETE PAYMENT
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
              <v-flex></v-flex>
            </v-container>
          </div>
        </div>
      </div>
    </div>
       <Footer></Footer>
  </div>
</template>
<script>
import * as $ from "jquery";
import NewSidebar from "@/components/Sidebar.vue";
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";
import VueTableDynamic from "vue-table-dynamic";

export default {
  name: "FundWallet",
  components: {
    TopNav,
    Footer,
    VueTableDynamic,
    NewSidebar,
  },
  data() {
    return {
      opt_active: "fundwallet",
      err_match: "",
      search: "",
      set_pin: {},
      isLoggedIn: this.$store.getters.isLoggedIn,
      my_permissions: {},
      balance: "",
      method: "",
      giftcard_amount: "",
      serialnumber: "",
      voucher: 0,
      payment: {
        voucher: false,
        loyalty: false,
        card: false,
      },
      loading: false,
      d_errors: null,
      clearance: false,
      available_balance: 0,
      perpage: 10,
      transaction: {
        balance: 0,
        user_id: this.$store.getters.user.id,
        type: "CREDIT",
        payment: [],
      },
      walletTransactions: [],
      page_url: null,
      pagination: {},
      params: {
        data: [
          [
            "Sn",
            "Id",
            "Order Number",
            "Amount Before",
            "Amount",
            "Amount After",
            "Channel",
            "Remarks",
            "Date",
          ],
        ],
        header: "row",
        stripe: true,
        enableSearch: true,
        sort: [0, 1, 3, 4, 5, 6, 7, 8, 9],
        pagination: true,
        pageSize: 10,
        pageSizes: [10],
        columnWidth: [
          { column: 0, width: "5%" },
          { column: 1, width: "5%" },
          { column: 2, width: "13%" },
          { column: 4, width: "10%" },
        ],
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },

    canPay() {
      if (this.isLoggedIn) {
        return (
          (this.transaction.balance > 0 && this.payment.card) ||
          (this.payment.voucher > 0 && this.transaction.balance)
        );
      } else {
        return false;
      }
    },
  },

  created() {
    this.fetchWalletTransactions();
    let rave = document.createElement("script");
    rave.setAttribute(
      "src",
      "https://ravesandboxapi.flutterwave.com/flwv3-pug/getpaidx/api/flwpbf-inline.js"
      // "https://api.ravepay.co/flwv3-pug/getpaidx/api/flwpbf-inline.js"
    );
    document.head.appendChild(rave);
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = "Your Wallet Page";
      },
    },
  },

  methods: {
    fetchWalletTransactions() {
      let req = {
        what: "wallet",
        showLoader: false,
        params: {
          user_id: this.user.id,
          search: this.search,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          let object = [];

          this.params.headings = [
            "sn",
            "id",
            "order_number",
            "transaction_type",
            "amount",
            "amount_after",
            "channel",
            "remarks",
            "date",
          ];
          this.params.counter = 1;
          // this.params.data = ["Sn", 'Id', 'Order Number', 'Amount Before', 'Amount' , 'Amount After','Channel', 'Remarks', 'Date'];

          this.walletTransactions = res.data.data;
          this.walletTransactions.forEach((val) => {
            object[0] = this.params.counter++;

            this.params.headings.forEach((key) => {
              if (key != "sn") object.push(val[key]);
            });

            this.params.data.push(object);

            object = [];
          });
        })
        .catch((err) => console.log(err));
    },

    makePagination(meta, links) {
      let pagination = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        next_page_url: links.next,
        prev_page_url: links.prev,
      };
      this.pagination = pagination;
    },

    formatUnique(n) {
      return Number(n) > 9 ? "" + n : "0" + n;
    },

    creditWallet() {
      this.transaction.unique_code =
        this.formatUnique(this.user.id) +
        Math.floor(10000 + Math.random() * 90000);
      let isValidate = [];
      let field = [];

      if (this.payment.loyalty) {
        if (
          this.transaction.payment.method.toLowerCase().includes("loyalty") ==
          false
        ) {
          this.transaction.payment.method += " loyalty";
        }
      } else {
        if (this.transaction.payment.method.toLowerCase().includes("loyalty")) {
          this.transaction.payment.method = this.transaction.payment.method.replace(
            " loyalty",
            ""
          );
        }
      }
      if (this.payment.voucher) {
        if (
          this.transaction.payment.method.toLowerCase().includes("gift") ==
          false
        ) {
          this.transaction.payment.method += " gift";
        }
      } else {
        if (this.transaction.payment.method.toLowerCase().includes("gift")) {
          this.transaction.payment.method = this.transaction.payment.method.replace(
            " gift",
            ""
          );
        }
      }
      if (this.payment.card) {
        if (
          this.transaction.payment.method.toLowerCase().includes("card") ==
          false
        ) {
          this.transaction.payment.method += " card";
        }
      } else {
        if (this.transaction.payment.method.toLowerCase().includes("card")) {
          this.transaction.payment.method = this.transaction.payment.method.replace(
            " card",
            ""
          );
        }
      }

      if (this.transaction.payment.method == "") {
        isValidate.push(false);
        field.push("payment method");
      }
      if (!isValidate.includes(false)) {
        console.log(this.transaction);
        this.transaction.amount = this.transaction.balance;
        // if (this.clearance = true) {
        let req = {
          what: "creditWallet",
          showLoader: true,
          data: this.transaction,
        };
        this.$request
          .makePostRequest(req)
          .then((res) => {
            if (this.transaction.payment.method.includes("gift")) {
              this.payGift(res.data.data);
            } else {
              this.payCard(res.data.data);
            }
            this.fetchWalletTransactions();
            $("#modal_aside_right").modal("hide");
          })
          .catch((error) => {
            console.log(error);
            this.$swal.fire("Error", error.message, "error");
          });
      } else {
        this.$swal.fire(
          "Error",
          `Kindly select your preferred ${field.toString()}`,
          "error"
        );
      }
    },

    paymethod($event, meth) {
      if ($event.target.checked) {
        if (meth == "voucher") {
          this.payment.voucher = true;
        }
        if (meth == "loyalty") {
          this.payment.loyalty = true;
        }
        if (meth == "card") {
          this.payment.card = true;
          this.transaction.payment.method = "card";
        }
      } else {
        if (meth == "voucher") {
          this.payment.voucher = false;
          this.balance = "";
          document.getElementById("statusvoucher").textContent = "";
          document.getElementById("balance").textContent = "";
          this.serialnumber = "";
          console.log(this.balance);
        }
        if (meth == "loyalty") {
          this.payment.loyalty = false;
          this.balance = "";
        }
        if (meth == "card") {
          this.payment.card = false;
          this.balance = this.transaction.balance;
        }
      }
    },

    verifyMethod(method) {
      let req = {
        what: "verifycard",
        showLoader: false,
        data: {
          serviceid: "351817683",
          serialnumber: this.serialnumber,
        },
      };
      this.$request
        .makePostRequest(req)
        .then((res) => {
          console.log(res);

          if (method == "voucher") {
            this.payment.voucher = true;
            this.giftcard_amount = res.data.data;
            document.getElementById("statusvoucher").textContent =
              "₦" + this.giftcard_amount;
            this.balance =
              Number(this.transaction.amount) - Number(res.data.data);
            document.getElementById("balance").textContent =
              "Balance= ₦" + this.balance;
          }
        })
        .catch((error) => {
          if (method == "voucher") {
            document.getElementById("statusvoucher").textContent = error;
            this.payment.voucher = false;
          }
          // else {
          //   document.getElementById('statusloyalty').textContent = res;
          //   this.payment.loyalty = false;
          // }
        });
    },

    formatPrice(price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },

    payCard(transaction, giftref) {
      // live
      // let PBFKey = "FLWPUBK-f079ea84da7aac9ca312a10668f88c44-X";

      // test
      let PBFKey = "FLWPUBK-00fd26c8dc92b4e1663550c4ba7532aa-X";
      let transid = giftref
        ? giftref
        : `${this.user.id}${Math.floor(Date.now())}`;
      let vm = this;
      let cardamount;
      if (this.balance !== "" && Number(this.balance) > 0) {
        cardamount = this.balance;
      } else {
        cardamount = this.transaction.balance;
      }
      getpaidSetup({
        PBFPubKey: PBFKey,
        customer_email: this.user.email,
        customer_firstname: this.user.firstname,
        customer_lastname: this.user.lastname,
        custom_description: "Payment for transaction made",
        custom_logo: "https//:marketsquareng.com/assets/img/logo_mobile.png",
        custom_title: "Market Square",
        amount: cardamount,
        customer_phone: this.user.phone,
        country: "NG",
        currency: "NGN",
        txref: this.transaction.unique_code,
        onclose: function() {},
        callback: function(response) {
          var flw_ref = response.tx.flwRef; // collect flwRef returned and pass to a 					server page to complete status check.
          let txref = response.tx.txRef;
          let status = response.tx.status;
          let amount = response.tx.amount;
          let chargeResponse = response.tx.chargeResponseCode;

          if (chargeResponse == "00" || chargeResponse == "0") {
            let req;

            req = {
              what: "verifywalletcredit",
              showLoader: true,
              data: {
                txref: txref,
                pref: flw_ref,
                transaction_id: transaction.id,
                user_id: vm.user.id,
                status: status,
                amount: amount,
              },
            };

            vm.$request
              .makePostRequest(req)
              .then((res) => {
                console.log(res);
                vm.$store.dispatch("user", res.data.data.user);
                vm.fetchWalletTransactions();
                vm.$swal.fire({
                  title: "Success!",
                  html: "Wallet TopUp Successful!!!",
                  timer: 5000,
                  onBeforeOpen: () => {
                    vm.$swal.showLoading();
                  },
                  onClose: () => {
                    clearInterval(
                      setInterval(() => {
                        const content = vm.$swal.getContent();
                        if (content) {
                          const b = content.querySelector("b");
                          if (b) {
                            b.textContent = vm.$swal.getTimerLeft();
                          }
                        }
                      }, 100)
                    );
                  },
                });
              })
              .catch((error) => {
                console.log(error);
                vm.$swal.fire("Error", error, "error");
              });
          } else {
            //Add your failure page here
            vm.$swal.fire({
              icon: "error",
              type: "error",
              title: "Error",
              text: "Payment Failed!!!",
            });
          }
        },
      });
    },
    payGift(transaction) {
      let vm = this;
      let req = {
        what: "redeemgiftwallet",
        showLoader: true,
        data: {
          serviceid: "351817683",
          serialnumber: this.serialnumber,
          phonenumber: this.user.phone.replace(/\s/g, ""),
          amount: this.giftcard_amount,
          order_id: transaction.id,
        },
      };
      this.$request
        .makePostRequest(req)
        .then((res) => {
          console.log(res);
          if (res.type == "redeemgift") {
            this.$swal.fire(
              "Success",
              "Giftcard Redeemed Successfully",
              "success"
            );
            if (this.balance !== "" && this.balance > 0) {
              this.payCard(transaction, res.data);
            } else {
              this.$store.dispatch("user", res.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire("Error", error, "error");
        });
    },

    setWalletPin() {
      this.set_pin.phone = this.$store.getters.user.phone;

      if (
        this.set_pin.password != undefined &&
        this.set_pin.pin1 != undefined &&
        this.set_pin.pin1 == this.set_pin.pin2
      ) {
        var req = {
          what: "newwalletpin",
          showLoader: true,
          data: this.set_pin,
        };
        this.$request
          .makePostRequest(req)
          .then((response) => {
            console.log(response);
            this.$swal.fire("Success", response.data, "success");
            $("#modal_aside_right_password").modal("hide");

            this.set_pin = {};
          })
          .catch((error) => {
            console.log(error);
            this.$swal.fire("Error", error, "error");
          });
      } else {
        this.$swal.fire(
          "Error",
          "Enter the form correctly to set wallet pin",
          "error"
        );
      }
    },
  },
};
</script>

<<style scoped>
#fund {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 1rem auto;
  border-radius: 10px;
  padding: 1rem;
  height: 62px;
  float: left;
  background-color: #ffffff;
  color: rgb(161, 143, 143);
  margin-top: -10px;
  width: 200%;
  max-width: 52rem;
}

#box {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 1rem auto;
  border-radius: 10px;
  padding: 1rem;
  height: 250px;
  float: left;
  background-color: #ffffff;
  margin-top: 13px;
  color: rgb(161, 143, 143);

  margin-right: 40px;
  width: calc(50% - 40px);
}
.modal-content {
  background-color: #f2f2fc;
  border: 0px;
}

.btnoptions {
  border: 2px solid #5424f6;
  background-color: white;
  color: #5424f6;
  font-size: 6px;
}

.stat {
  background-color: #ffe1e1;
}
.statt {
  background-color: #f0f5f1;
}
#boxx {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 1rem auto;
  border-radius: 10px;
  padding: 2.5rem;
  height: 500px;
  float: left;
  margin-top: -11px;
  margin-left: 40px;

  background-color: #ffffff;
  color: rgb(161, 143, 143);

  width: calc(50% - 40px);
}

.text {
  text-align: center;
  color: black;
  margin-top: -16px;
  font-size: 14px;
}
.dep {
  color: black;
  font-size: 12px;
  margin-top: -10px;
}
.arrow {
  width: 17%;
  position: relative;
  top: 0px;
  left: -49%;
}
.cardicon {
  width: 25%;
  position: relative;
  top: -3px;
  left: -14%;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none;
  border: 0px;
}
.text-left {
  margin-left: 6px !important;
  margin-top: 5px !important;
}
.arr-left {
  margin-left: 6px !important;
  margin-top: 5px !important;
}
</style>
