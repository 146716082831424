<template>
<div class="">
    <Disclaimer />
    <TopNav></TopNav>
    <Tops></Tops>
    <div class="page">
      <div class="">
       <div class="banner home-banner content-desktop" style="text-align: center !important; ">
            <div class="container">
            <div class="banner-text">
              <h3 class="title">Let’s take the burden off you. <br> Shop & get it delivered to your doorstep</h3>
              <p class="subtitle">Food, drinks, groceries, and more available for delivery and pickup.</p>

            </div>
          </div> 

           <slider
            class="d-none d-md-block"
            height="400px"
            :ease="0.5"
            :interval="8000"
            :speed="1000"
            :control-btn="false"
            :indicators="false"
          >
            <slider-item
              v-for="(i, index) in banners"
              :key="index"
              :style="i"
            >
              <img
                :src="image_url+i.img_url"
                width="100%"
                 height="100%"
                alt=""
                class="router"
                @click="$router.push(i.url.toLowerCase() != '' ? i.url.toLowerCase() :  '/home')"
              >
            </slider-item>
          </slider> 

          
          <slider
            class="d-block d-md-none"
            :duration="10000"
            height="150px"
            :speed="8000"
            :control-btn="false"
            :indicators="false"
          >
            <slider-item
              v-for="(i, index) in banners"
              :key="index"
              :style="i"
            >
              <img
                :src="image_url+i.img_url"
                alt=""
                width="100%"
                class="router"
                @click="$router.push(i.url.toLowerCase() != '' ? i.url.toLowerCase() :  '/home')"
              >
            </slider-item>
          </slider>
           <div class="content container" style="margin-top: 79px;">
             <div class="product-group  container bg-white my-5"   style= "justify-content: center;">
                  <br>
                  <a href="/refer">
                  <img class="arr-left" src="@/assets/img/back.png" width = "13" height = "12" style="margin-left: -1001px;"/> Back </a>
                  <p><b>Referred Customers</b></p>
                  
                
             </div>
          </div>
        </div>
      </div>
      </div>
    </div>
</div>    
</template>

<script>
import StoreSelector from '@/components/StoreSelector.vue'
import Cart from '@/components/CartComponent.vue'
import TopNav from '@/components/TopNav.vue'
import Tops from '@/components/Tops.vue'
import Footer from '@/components/Footer.vue'
import Disclaimer from '@/components/Disclaimer.vue'
import Questionnaire from "@/components/Questionnaire";
import Product from '@/components/Product.vue'
import ProductModal from '@/components/ProductModal.vue'
import pageDescription from '@/components/pageDescription.vue'
import  ClientTable from 'vue-tables-2';
export default {
 name: ' ReferredCustomers',
  components: {
     ClientTable, StoreSelector,Cart,TopNav, Tops, Footer, Disclaimer, Questionnaire, Product, ProductModal, pageDescription
  },
   data () {
    return {
      customers:[],
       image_url: this.$request.url,
      banners:[],
      month: "",
      day: '',
      terms: '',
      confirmpassword: '',
      passwordFieldType: 'password',
      user: {
        title: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        subscribe: false,
        gender: "",
        dob: ""
      },

      columns: ["sn", "name", "email", "phone","status", "date" ],
      options: {
        headings: {
          sn: "S/No",
          name: "Name",
          email: "Email",
          phone: "Phone",
          status: "Status",
          date: "Date of Registration",
        

        },
        sortable: [ "name", "email", "phone", "status", "date",],
        filterable:  ["sn", "name", "email", "phone","status", "date" ],
        saveState: true,

      }
    }
  },
  mounted(){
    this.fetchBanners();

  },

   watch: {
    $route: {
        immediate: true,
        handler(to, from) {
            document.title = 'Referred Customers';
        }
    },
  },
  methods:{
 fetchBanners () {
      let req = {
        what: "banners",
        showLoader: false,
      }
      this.$request.makeGetRequest(req)
          .then(response => {

            if (response.type == 'banners') {
              this.banners = response.data.data.filter( (image) => image.category == null || image.category == 'null');
              this.$store.dispatch('banners', response.data.data)

            }
          })
          .catch(error => {

            console.log(error)
          });
    },
    fetchCategories () {
      let req = {
        what: "getCategories",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
          .then(response => {

            if (response.type == 'getCategories') {
              this.categories = response.data.data
              this.$store.dispatch('categories', response.data.data)

            }
          })
          .catch(error => {

            console.log(error)
          });
    },
  },
  }
</script>
<style>
/*.row{
  justify-content:center;
  margin-top:100px;
}*/
#desbox {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 10px;
  border-radius: 10px;
  padding: 1px;
  height: 100px;
  background-color: white;
  margin-top: 12px;
  color: rgb(161, 143, 143);

  width: 100px;
}
.form-group{
    text-align:left;
}

#desbox img{
  display: block !important; 
  margin-left: auto !important; 
  margin-right: auto !important; 
  margin-top: 14px !important;
  width: 75px !important;
  
}
</style>