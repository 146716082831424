<template>
  <div>
    <TopNav></TopNav>
    <Tops></Tops>
    <div class="address page bg-white py-5">
      <div class="container">
        <div class="row">
          <NewSidebar v-bind:opt_active="opt_active" />
          <div class="col-sm-9">
            <div class="content">
              <h4 class="title text-left">
                 <a href="/newwallet">
                  <img class="arr-left" src="@/assets/img/back.png" width = "13" height = "12"/> </a>
                Set Wallet Pin</h4>
              <p><small>Complete the form to set a new wallet pin</small></p>

              <div class="col-sm-9 mt-3 p-0">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(setWalletPin)">
                    <div class="form-group" style="position:relative">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <label>Enter current password</label>
                        <input
                          class="form-control"
                          placeholder="Enter your current password"
                          v-model="set_pin.password"
                          type="password"
                        />
                        <span class="err_msg">{{ errors[0] }}</span>
                      </validation-provider>
                       
                    </div>

                    <div class="form-group" style="position:relative">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <label>Enter new pin</label>
                        <input
                          class="form-control"
                          placeholder="Enter New Pin"
                          v-model="set_pin.pin1"
                          type="password"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          minlength="4"
                          maxlength="6"
                          onfocus="this.placeholder = 'Enter 4 to 6 digits number'"
                          onblur="this.placeholder = 'Enter New Pin'"
                          v-on:keyup="
                            set_pin.pin1 != set_pin.pin2
                              ? (err_match =
                                  'Enter the same Pin in confirm pin')
                              : (err_match = '')
                          "
                        />
                        <span class="err_msg">{{ errors[0] }} </span>
                      </validation-provider>
                    </div>

                    <div class="form-group" style="position:relative">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <label>Confirm new pin</label>
                        <input
                          class="form-control"
                          label=""
                          placeholder="Confirm your Pin"
                          v-model="set_pin.pin2"
                          type="password"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          minlength="4"
                          maxlength="6"
                          v-on:keyup="
                            set_pin.pin1 != set_pin.pin2
                              ? (err_match =
                                  'The confirm does not match the Pin')
                              : (err_match = '')
                          "
                        />

                        <span class="err_msg"
                          >{{ errors[0] }} {{ err_match }}</span
                        >
                      </validation-provider>
                    </div>

                    <button class="msq-button mt-3">Set Wallet Pin</button>
                  </form>
                </ValidationObserver>
              </div>
              <div class="avatar col-3"></div>
            </div>
          </div>
        </div>
      </div>
      <storeSelector></storeSelector>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import StoreSelector from "@/components/StoreSelector.vue";
import Tops from '@/components/Tops.vue'
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";
import NewSidebar from "@/components/Sidebar.vue";
export default {
  name: "SetWalletPin",
  components: {
    StoreSelector,
    TopNav,
    Footer,
    NewSidebar,
    Tops
  },
  data() {
    return {
       opt_active: 'walletpin',
      err_match: "",
      search: "",
      set_pin: {},
      my_permissions: {},
      balance: "",
      method: "",
      giftcard_amount: "",
      serialnumber: "",
      voucher: 0,
      payment: {
        voucher: false,
        loyalty: false,
        card: false,
      },
      loading: false,
      d_errors: null,
      clearance: false,
      perpage: 10,
      transaction: {
        balance: 0,
        user_id: this.$store.getters.user.id,
        type: "CREDIT",
        payment: [],
      },
    };
  },

  mounted() {
    this.user = this.$store.getters.user;
    console.log(this.user);
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = "Set Wallet Pin";
      },
    },
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    setWalletPin() {
      this.set_pin.phone = this.$store.getters.user.phone;

      if (
        this.set_pin.password != undefined &&
        this.set_pin.pin1 != undefined &&
        this.set_pin.pin1 == this.set_pin.pin2
      ) {
        var req = {
          what: "newwalletpin",
          showLoader: true,
          data: this.set_pin,
        };
        this.$request
          .makePostRequest(req)
          .then((response) => {
            console.log(response);
            this.$swal.fire("Success", response.data, "success");
          

            this.set_pin = {};
          })

          .catch((error) => {
            console.log(error);
            this.$swal.fire("Error", error, "error");
          });
      } else {
        this.$swal.fire(
          "Error",
          "Enter the form correctly to set wallet pin",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
#show_hide {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 18px;
}
.text-left{
  margin-left:-21px ! important;
   margin-top:5px ! important;
}
.arr-left{
  margin-left:-4px ! important;

}
</style>
