<template>
  <div>
    <TopNav></TopNav>
    <Tops></Tops>
    <div class="address page bg-white py-5">
      <div class="container">
        <div class="row">
          <NewSidebar v-bind:opt_active="opt_active" />

          <div class="col-sm-9 display-none">
            <div>
              <h3 class="title text-left"><b>My Wallet</b></h3>
              <div class="col-lg-3 " style="float:right; left:33px">
                <a href="/fundwallet"
                  ><button class="btn newbtn form-control">Fund Wallet</button>
                </a>
              </div>
              <div class="col-lg-3" style="float:right; left:55px">
                <a href="/walletpin">
                  <button class="btn newbtn form-control">
                    Set Wallet Pin
                  </button></a
                >
              </div>
            </div>
            <br /><br />
            <div class="title text-left">
              <h6>
                <strong
                  >Wallet Balance:&nbsp;₦{{
                    Number(user.wallet_balance).toLocaleString()
                  }}</strong
                >
              </h6>
              <p style="font-size:12px">
                <strong>
                  Available Balance:&nbsp;₦{{
                    Number(user.available_balance).toLocaleString()
                  }}
                </strong>
              </p>
            </div>
            <div class="card-body">
              <div class="row filters mb-3 d-flex justify-content-end">
                <div class="show row">
                  <span class="row" style="font-size:13px; margin-top:7px"
                    >Show</span
                  >
                  <span>
                    <select
                      class="row form-control"
                      style="margin: -7px 15px; width: 65px;"
                      v-model="setPage"
                      @change="
                        maxperPage = setPage;
                        perPage = setPage;
                      "
                    >
                      <option value="10" selected>10</option>
                      <option value="15">15</option>
                      <option>20</option>
                      <option>25</option>
                      <option>30</option>
                      <option>35</option>
                    </select>
                  </span>
                  <span class="row" style="font-size:13px; margin-top:7px"
                    >Transactions</span
                  >
                </div>
                <div class="">
                  <input
                    type="text"
                    class="form-control"
                    style="width: 90%;margin-right: 40px;"
                    v-model="search"
                    placeholder="Filter by amount, remarks ..."
                  />
                </div>

                <!-- <div class="mx-2">
                      
                  <input
                    type="text"
                    v-model="start_date"
                    class="form-control"
                    placeholder="Start Date"
                     onmouseover="(this.type='date')"
                    onmouseout="if(this.value == ''){this.type='text'}"
                  />
                   
                </div>
                
                <div class="">
                  <input
                    type="text"
                    v-model="end_date"
                    class="form-control"
                    placeholder="End Date"
                    onmouseover="(this.type='date')"
                    onmouseout="if(this.value == ''){this.type='text'}"

                  />
                </div>-->
                <!--Filter-->
               
    <div
      class="modal fade"
      id="filter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Date Filter</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            
              <form >
                <div class="form-group">
              
                  <label class="bmd-label-floating clr-black"
                              >Start Date</label
                            >
                     <input
                    type="Date"
                    v-model="addSearch.start_date"
                    class="form-control"
          
                   
                  />
                  
                   
                </div>

                <div class="form-group">
                 
                   <label class="bmd-label-floating clr-black"
                              >End Date</label
                            >
                     <input
                    type="Date"
                    v-model="addSearch.end_date"
                    class="form-control"
                    placeholder="End Date"
                    
                  />
                  
               
                </div>
                <div class="form-group">
                  <button
                    type="submit"
                    class="msq-button mt-3"
                      @click.prevent="multipleSearch()"
                  >Submit</button>
                </div>
              </form>
  
          </div>
          </div>
</div>
</div>


          
       
   

                <p>
                  <button
                    class="btn newbtn btn-primary"
                    type="button"
                    data-toggle="modal"
                    style="font-size:13px; margin-top:7px"
                    data-target="#filter"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Date Filter
                  </button>
                </p>
                <div class="collapse" id="collapseExample">
                  <div
          
                    style="width:163%;margin-right: 71px;">
                    <div class="row filters mb-12 d-flex justify-content-end"
                    style="margin-left: 550px;">
                      <div
                        class="wrapper"
                        style="width:30%;margin-right: 10px;"
                      >
                        <input
                          type="text"
                          v-model="addSearch.start_date"
                          class="form-control"
                          placeholder="Start Date"
                          onmouseover="(this.type='date')"
                          onmouseout="if(this.value == ''){this.type='text'}"
                        />
                      </div>
                      <div style="width:30%">
                        <input
                          type="text"
                          v-model="addSearch.end_date"
                          class="form-control"
                          placeholder="End Date"
                          onmouseover="(this.type='date')"
                          onmouseout="if(this.value == ''){this.type='text'}"
                        />
                      </div>
                      <button
                        class="btn newbtn"
                        style="width:15%; margin-left:3px;margin-top:8px"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row"></div>

              <div
                id="modal_transaction"
                class="modal fixed-left fade"
                tabindex="-1"
                role="dialog"
              >
                <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content" style="height:431px; width:845x">
                    <div class="modal-header">
                      <h5 class="modal-title">Transaction History</h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <TransactionModal
                      v-bind:pageInfo="pageInfo"
                    ></TransactionModal>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <table class="table tsp table-striped">
                <tbody>
                  <tr>
                    <th scope="col">SN</th>
                    <th scope="col">ID</th>
                    <th scope="col">Order No</th>
                    <th scope="col">Transaction Type</th>
                    <th scope="col">Amount[₦]</th>
                    <th scope="col">Amount After[₦]</th>
                    <th scope="col">Date</th>
                    <th scope="col">Action</th>
                  </tr>
                  <tr
                    v-for="(x, index) in filter.slice(startPage, perPage)"
                    :key="index"
                  >
                    <th scope="row">{{ index + startPage + 1 }}</th>
                    <td>{{ x.id }}</td>
                    <td>{{ x.order_number }}</td>
                    <td>{{ x.type }}</td>
                    <td>
                      <span v-if="x.amount >= 0">
                        <img
                          class="arrow"
                          src="@/assets/img/arrowdown.png"
                          style="width:15px"
                        />
                      </span>

                      <span v-if="x.amount < 0">
                        <img
                          class="arrow"
                          src="@/assets/img/arrowup.png"
                          style="width:15px"
                        />
                      </span>

                      {{ Number(x.amount).toLocaleString() }}
                    </td>
                    <td>{{ Number(x.amount_after).toLocaleString() }}</td>
                    <td class="date_td">
                      {{ mydatef(x.date) }}
                    </td>
                    <td>
                      <button
                        class="vmbtn"
                        data-toggle="modal"
                        data-target="#modal_transaction"
                        @click="loadTbData(index + startPage)"
                      >
                        View More
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <small
                  >Showing {{ maxperPage }} - {{ endPage }} Transactions of
                  {{ totalPage }}</small
                >
              </p>
              <nav aria-label="..." style="float:right">
                <ul class="pagination">
                  <li class="page-item disabled">
                    <a class="page-link " href="#" tabindex="-1">&#60;</a>
                  </li>

                  <li
                    class="page-item "
                    v-for="(i, index) in pagenate_ui_arr"
                    :key="index"
                  >
                    <a
                      class="page-link "
                      href="#"
                      @click="
                        perPage = maxperPage * i;
                        startPage = perPage - maxperPage;
                        endPage = perPage;
                      "
                      >{{ i }}</a
                    >
                  </li>

                  <li class="page-item" >
                    <a class="page-link" href="#">&#62;</a>
                  </li>
                </ul>
              </nav>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NewSidebar from "@/components/Sidebar.vue";
import Tops from '@/components/Tops.vue'
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";
import FilterModal from "@/components/FilterModal.vue";
import axios from "axios";
import TransactionModal from "@/components/TransactionModal.vue";
import * as $ from "jquery";

export default {
  name: "NewWallet",
  components: {
    Tops,
    TopNav,
    Footer,

    NewSidebar,
    TransactionModal,
  },
  data() {
    return {
      opt_active: "wallet",
       addSearch: {},
      perPage: 10,
      maxperPage: 10,
      setPage: 1,
      endPage: 0,
      startPage: 0,
      pagenate_ui_arr: [],
      pageInfo: {},
      err_match: "",
      search: "",
      start_date: "",
      end_date: "",
      my_permissions: {},
      balance: "",
      method: "",
      serialnumber: "",
      loading: false,
      transaction: {
        balance: 0,
        user_id: this.$store.getters.user.id,
        type: "CREDIT",
        payment: [],
      },
      walletTransactions: [],
      page_url: null,
      pagination: {},
      params: {
        data: [
          [
            "Sn",
            "Id",
            "Order No.",
            "Transaction Type",
            "Amount[₦]",
            "Amount After[₦]",
            "Date",
            "Action",
          ],
        ],
      },
      totalPage: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    filterSearch() {
      return this.search.trim() != ""
        ? this.walletTransactions.filter((data) => {
            return JSON.stringify([
              data.id,
              data.ordernumber,
              data.remarks,
              data.amount,
              data.type,
            ])
              .replace("[", "")
              .replace("]", "")
              .toLowerCase()
              .includes(this.search.toLowerCase());
          })
        : this.walletTransactions;
    },

    

    filter() {
      if (this.start_date.trim() != "" && this.end_date.trim() != "") {
        let result = this.filterSearch.filter((data) => {
          return (
            (this.start_date.trim() != ""
              ? new Date(this.start_date).getTime() <=
                new Date(data.date).getTime()
              : true) &&
            (this.end_date.trim() != ""
              ? new Date(this.end_date).getTime() >=
                new Date(data.date).getTime()
              : true)
          );
        });
        this.customPagenation(result);
        this.totalPage = result.length;
        return result;
      } else {
        this.customPagenation(this.filterSearch);
        this.totalPage = this.filterSearch.length;
        return this.filterSearch;
      }
    },
  },
  created() {
    this.fetchWalletTransactions();
    let rave = document.createElement("script");
    rave.setAttribute(
      "src",
      "https://ravesandboxapi.flutterwave.com/flwv3-pug/getpaidx/api/flwpbf-inline.js"
      // "https://api.ravepay.co/flwv3-pug/getpaidx/api/flwpbf-inline.js"
    );
    document.head.appendChild(rave);
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = "Your Wallet Page";
      },
    },
  },

  methods: {
    
    multipleSearch() {
      this.loading = true;
    
            $("#filter").modal("hide");
      //   this.walletTransaction.status = "COMPLETED";
      let req = {
        what: "multiplefilter",
       showLoader: false,
         params: {
           store_id: this.$store.getters.store.id
         }
        
      }
      req.body = this.addSearch;
     // this.addSearch.user_id=this.$store.getters.user.id;   
      this.$request.makePostRequest(req)
        /*  .then(response => {
      fetch("api/admin/walletTransactions/multiplefilter", {
        method: "post",
        body: JSON.stringify(this.addSearch),
        headers: {
          "content-type": "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]'),
        },
      }) */
        .then((res) => {
         
          if (res.data.status !== 200 && res.data.status !== 201) {
            this.loading = false;
            
          }
          return res.data;
        })
        .then((res) => {
          
          this.loading = false;
           
          this.walletTransactions = [];
          this.walletTransactions = res.data;
          console.log(res.data);
          
        })

        

        .catch((err) => console.log(err));
    },
  
    fetchWalletTransactions() {
      let req = {
        what: "wallet",
        showLoader: false,
        params: {
          user_id: this.user.id,
          search: this.search,
        },
      };
      this.$request
        .makeGetRequest(req)
        .then((res) => {
          let object = [];

          this.params.headings = [
            "sn",
            "id",
            "order_number",
            "type",
            "amount",
            "amount_after",
            "date",
            "action",
          ];
          this.params.counter = 1;
          //this.params.data = ["Sn", 'Id', 'Order Number', 'Amount Before', 'Amount' , 'Amount After','Channel', 'Remarks', 'Date'];

          this.walletTransactions = res.data.data;
          this.walletTransactions.forEach((val) => {
            object[0] = this.params.counter++;

            this.params.headings.forEach((key) => {
              if (key != "sn") {
                if (key == "date") {
                  let formated_date = new Date(val[key]).toGMTString();
                  formated_date = formated_date
                    .split(" ")
                    .slice(0, 5)
                    .join(" ");
                  //console.log(formated_date);
                  object.push(formated_date);
                } else {
                  object.push(val[key]);
                }
              }
            });

            this.params.data.push(object);

            object = [];
          });
        })
        .catch((err) => console.log(err));
    },

    loadTbData(x) {
      this.pageInfo = this.filter[x];
    },
    formatUnique(n) {
      return Number(n) > 9 ? "" + n : "0" + n;
    },
    formatPrice(price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },

    customPagenation(x) {
      this.pagenate_ui_arr = [];
      let l = x.length;
      let p = this.maxperPage;
      let pagenate_ui = Math.ceil(l / p);
      for (let i = 0; i < pagenate_ui; i++) {
        this.pagenate_ui_arr.push(i + 1);
      }
    },

    mydatef(x) {
      let formated_date = new Date(x).toGMTString();
      formated_date = formated_date
        .split(" ")
        .slice(0, 5)
        .join(" ");
      return formated_date;
    },
       
        
      
  },

};
</script>
<style scoped>
select.form-control {
  height: 36px !important;
}
.newbtn {
  background-color: #4c5bb8;
}
.vmbtn {
  border: 0.5px solid #5424f6;
  background-color: white;
  color: #5424f6;
  font-size: 8px;
  border-radius: 3px;
}

.date_td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  width: 166px;
  display: block;
}
td,
th {
  padding: 4px !important;
  font-size: 12px !important;
  border: none;
}
.tsp {
  margin-top: -2rem !important;
}
.page-link {
  color: #000;
}
.page-item.active .page-link {
  background-color: #5424f6;
  border-color: #5424f6;
}
.page-link {
  padding: 5px 8px;
  font-size: 12px;
}
.page-link {
  padding: 5px 8px;
  font-size: 12px;
}
.show {
  margin-right: 318px;
  font-size: 13px;
  margin-top: 16px;
}
</style>
