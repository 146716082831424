<template>
    <div id="">

      <!-- mobile end -->
      <div id="content-mobile">
    
          <div id="topnav" class="top" style="background: #000066 0% 0% no-repeat padding-box;">
         <!-- store not selected -->
         <div style="display: flex;justify-content: center;">
                <a
                  v-if='$store.getters.isStoreSet==false'
                  href=""
                  data-toggle="modal"
                  data-target="#store"
                  style="color:white" 
                   >
                  <img
                    src="../assets/img/stores.png"
                    alt=""
                    style="height:15px"
                  >
                  Select Store

                </a>
                <!-- store selected -->
                <a
                  v-else
                  href=""
                  data-toggle="modal"
                  data-target="#store"
                  style="color: white"
                >
                  <img
                    src="../assets/img/stores.png"
                    alt=""
                    class="mr-1"
                    style="height:21px;margin-top:3px" >
                  <span v-if="this.$store.getters.store.mode =='Delivery'" style="color: white;text-align: center;font-size: 12px;">
                <!--  Delivering to <b>{{this.$store.getters.area}}</b> 
                  from--> <b>{{ storename(this.$store.getters.store.name)}} Store</b> 
                  
                  
                    <img
                    src="../assets/img/maps.png"
                    alt=""
                    class="mr-1"
                    style="height:21px; margin-left:15px;margin-right:2px; margin-top:3px" >
                  <b>{{this.$store.getters.area}}</b> 
                  </span>
                  <!--Pickup from-->
                  <span v-else
                   style="color: white;text-align: center;font-size: 9px;" >
                   <b>{{ storename(this.$store.getters.store.name)}} Store</b>  
                    
                      <img
                    src="../assets/img/maps.png"
                    alt=""
                    class="mr-1"
                     style="height:21px; margin-left:15px;margin-right:2px; margin-top:3px" > 
                
                   <b>{{this.$store.getters.area}}</b> 
                   </span>

                  <a
                    v-if="$route.name !==('Checkout') && $route.name !==('StoreLocator')"
                    href=""
                    class="badge badge-pill badge-light ml-1"
                    data-toggle="modal"
                    data-target="#store"
                    style=" border-radius: 3px; font-size:10px"
                  >Change Location</a>
                </a>
              </li>

            </ul>
        
             </div>
              

          </div>

      </div>

      <!-- Product Modal -->
      <div
        class="modal fade"
        data-backdrop="false"
        id="fulfillmentmode"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h5>Fulfillment Mode</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="placeOrder()">

                <v-select
                  required
                  :options="['Pickup', 'Delivery']"
                  v-model="method"
                  placeholder="Select fulfillment mode"
                  class="form-group"
                >
                </v-select>
                <v-select
                  v-if="method== 'Delivery'"
                  required
                  :options="areas"
                  v-model="area"
                  placeholder="Select Delivery Area"
                  class="form-group"
                >
                  <template #no-options="{ searching, loading }">
                    Please wait...
                  </template>
                  <span slot="no-options">Sorry, no matching options</span>
                </v-select>

                <button class="btn mx-auto text-center">Submit</button>
              </form>
            
           </div>
          </div>
        </div>
          </div>
          </div>
          </div>
      </div>
    
</template>


<script>
import * as $ from "jquery";
import Search from "@/components/Search.vue";
import Searchmobile from "@/components/Searchmobile.vue";
import FreeDelivery from '@/components/FreeDelivery.vue'
export default {
  name: 'Tops',
  props: {
    msg: String
  },
  components: {Search, Searchmobile, FreeDelivery},
  data () {
    return {
      
      searchQuery: '',
      hamper_status: 0,
      cats : {
        food : ['commodities', 'perishable', 'butchery', 'fresh produce', 'condiments'],
        appliances_acessories : ['sda', 'accessories', 'household'],
        drinks : ['water', 'spirits'],
        health_beauty : ['medicare', 'toiletries'],
        others : ['pet products', 'baby products', 'automobile', 'bms', 'general'],
        groceries : ['beverage', 'confectioneries', 'breakfast cereal', 'groceries']
      },
      
      user: {},
      cart: [],
      store: {},
      method: '',
      areas: [],
      area: '',
      promotion: [],
      category: [],
      initials:[],
    }
  },
  mounted () {
   
       
    
    window.scrollTo(0, 0)
    if (this.$route.name == 'Search') {
      this.searchQuery = this.$route.params.search
    }
    this.user = this.$store.getters.user;
    if (this.$store.getters.storesCart.length > 0) {
      this.cart = this.$store.getters.storesCart;
      // console.log(this.cart)
    }
    // this.getHamperStatus();
    // if(this.$store.getters.isStoreSet !=false) {
    //   this.fetchCategories();
    //   this.fetchPromotions();
    // }
 this.closeNav();
       

 
  },
  watch: {
    method (val) {
      if (val == 'Delivery') {

        let req = {
          what: "areas",
          showLoader: false
        }
        this.$request.makeGetRequest(req)
          .then(response => {
            if (response.type == 'areas') {
              let zones = response.data.data
              let vm = this;
              zones.forEach(i => {
                i.areas.forEach(j => {
                  j.store.forEach(k => {
                    if (k == vm.store.storeid) {
                      vm.areas.push(j.area)
                    }
                  })
                })
              })
            }
          })
          .catch(error => {

            console.log(error)
          });


      }
    }

  },
  computed: {
    cart_total () {
      let total = 0;
      this.cart.forEach(i => {
        total += Number(i.total_price);
      })
      return total
    },
    categories () {
      if(this.$store.getters.categories != [] ) {
        this.category = this.$store.getters.categories;
        return this.$store.getters.categories;
      } else if(this.category != []) {    
        return this.category;
      } else {
        this.fetchCategories();
      }
    },
    promotions() {
      if(this.$store.getters.promotions != [] ) {
        return this.$store.getters.promotions.filter((val) => val != null  && val != "null" && val != "NULL");
      } else if(this.promotion != []) {
        return this.promotion.filter((val) => val != null  && val != "null" && val != "NULL");
      } else {
        this.fetchPromotions();
      }
    },
  },
  methods: {
    fetchCategories () {
      let req = {
        what: "getCategories",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
          .then(response => {

            if (response.type == 'getCategories') {
              this.category = response.data.data
              this.$store.dispatch('categories', response.data.data)


            }
          })
          .catch(error => {

            console.log(error)
          });
    },
    fetchPromotions () {
      let req = {
        what: "getPromotions",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
          .then(response => {

            if (response.type == 'getPromotions') {
              this.promotion = response.data.data.filter((val) => val != null  && val != "Null" && val != "NULL")
              this.$store.dispatch('promotions', this.promotion);
            }
          })
          .catch(error => {

            console.log(error)
          });
    },
    showCategory(name){
      return this.categories.includes(name);
    },
    getSubCategories(name) {
      return this.$store.getters.categories.filter( (val) => this.cats[name].includes(val))
    },
     setCategoryRoute (route) {
      this.$store.dispatch('setCategoryRoute', route);
    },
    getHamperStatus() {
      let req = {
          what: "hamperStatus",
          showLoader: false
        }
        this.$request.makeGetRequest(req)
          .then(response => {
            if (response.type == 'hamperStatus') {
              this.hamper_status = response.data;
              // this.$store.dispatch('hamper_status', response.data.data);
            }
          })
          .catch(error => {

            console.log(error)
          });

    },
    openNav () {
      document.getElementById("mySidepanel").style.width = "100%";
    },
    closeNav () {
      document.getElementById("mySidepanel").style.width = "0";
    },
    toggleDropdown (e) {
      document.querySelectorAll('.dropdown-btn').forEach(el => {

        if ((el.nextElementSibling.style.display === "block") && (el != e.target)) {
          el.classList.remove('active');
          el.nextElementSibling.style.display = "none";
        }
      })
      e.target.classList.toggle("active");
      var dropdownContent = e.target.nextElementSibling;
      if (dropdownContent.style.display === "block") {
        dropdownContent.style.display = "none";
      } else {
        dropdownContent.style.display = "block";
      }
    },
    search () {
      if (this.$route.name !== 'Search') {
        this.$router.push(`/search/${this.searchQuery}`)
      }
      else {
        this.$router.push({ name: 'Search', params: { search: this.searchQuery } })
        // this.$parent.fetchProducts()
        this.$router.go();
      }


    },
    logOut () {
      this.$store.dispatch('setLoggedIn', false)
      this.$store.dispatch('user', {})
      this.$router.push('home');
    },
    placeOrder () {
      console.log(this.store);
      let cart_array = [];
      this.store.cartitem.forEach(i => {
        let x = {
          quantity: i.quantity,
          unit_price: i.unit_price,
          price: i.price,
          product: {
            id: i.product_id,
            name: i.name,
            img_url: i.img_url,
            price: i.sellingprice
          }
        }
        cart_array.push(x)
      })
      if (this.method == 'Delivery') {
        this.$store.dispatch("area", this.area);
      }
      let store;
      let allstores = this.$store.getters.allstores;
      allstores.forEach(i => {
        if (i.id == this.store.storeid) {
          store = i;
        }
      })
      store.mode = this.method;

      this.$store.dispatch("setStoreStatus", true);
      this.$store.dispatch("setStore", store).then(res => {
        this.$store.dispatch('addToCart', cart_array)
        $(".modal").modal("hide")
        this.$router.push({ name: 'Cart' })
      })

    },
    closeModal () {
      $(".modal").modal("hide")
    },
    getStore (id) {
      let stores = this.$store.getters.allstores;
      let name;
      stores.forEach(i => {
        if (i.id == id) {
          name = i.name;
        }
      })
      return name;
    },


    Ab(x){
   
          var str = this.user.fullname;
          var matches = str.match(/\b(\w)/g);
          //var acronym = matches.join('');
           // init.push(matches);
           // sm.push(init[0]);
       var init = matches[1]+ matches[2];
       return init;
            console.log(init);
           
    },
        
     storename(a){

       var stname = this.$store.getters.store.name;
        var sing = stname.toString().split(" ",4);
        if (sing.length >= 4) {
          return sing[2] + " " + sing[3]
          }else{
             return sing[2]
          }
         console.log(sing[2] + sing[3]);
     },    
    
    formatPrice (price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str[0];
    },
  }
}
</script>


<style scoped>
#user li,
li#user {
  list-style: none;
  position: relative;
}
#user .dropdown-menu li a,
li#user .dropdown-menu li a {
  color: #000066;
  font-size: 12px;
  font-weight: bold;
  position: relative;
}
.searchInput {
    border:none;
    background: none;
    outline:none;
    float:left;
    padding: 0;
    color: white;
    font-size: 16px;
    transition: 0.4s;
    line-height: 50px;
    width: 0px;
}
#user .dropdown-menu li:hover,
#user .dropdown-menu li:hover a,
li#user .dropdown-menu li:hover,
li#user .dropdown-menu li:hover a {
  background: #000066;
  color: #fff;
}
#user .dropdown-menu li a .material-icons,
li#user .dropdown-menu li a .material-icons {
  font-size: 15px;
  position: absolute;
  top: -2px;
}
#username {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
}

form{
    position: relative;
    top: 60%;
    left: 10%;
    transform: translate(-50%,-50%);
    transition: all 1s;
   width:100px;
    height: 40px;
    background: white;
    box-sizing: border-box;
    border-radius: 25px;
    border: 4px solid white;
    padding: 5px;
}
#newf{
   position: relative;
    top: 60%;
    left: 10%;
    transform: translate(-50%,-50%);
    transition: all 1s;
   width:204px;
    height: 40px;
    background: white;
    box-sizing: border-box;
    border-radius: 25px;
    border: 4px solid white;
    padding: 5px;
}
input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;;
    height: 42.5px;
    line-height: 30px;
    outline: 0;
    border: 0;
    display: none;
    font-size: 1em;
    border-radius: 20px;
    padding: 0 20px;
}

.fa{
    box-sizing: border-box;
    padding: 10px;
    width: 42.5px;
    height: 42.5px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    color: #07051a;
    text-align: center;
    font-size: 1.2em;
    transition: all 1s;
}

form:hover,
form:valid{
    width: 250px;
    cursor: pointer;
}

form:hover input,
form:valid input{
    display: block;
}

form:hover .fa,
form:valid .fa{
    background:#E50808;
    color: white;
}

.button{
  background:white;
    border-color: #e7e7e7;
    color:#060669;
}

.butt{
  background:#000066;
    border-color: #000066;
    color:white;
    width:90px;
    margin-right:-154px;
    }
.init{
  color: #000066;
  font-size:50px;
  text-align:center;
  font-family: harabara!important;
  
  
}

.content-desktop {display: block;}
#content-mobile {display: none;}

@media screen and (max-width:500px) {

.content-desktop {display: none;}
#content-mobile {display: block;}

}
#userpoint {
  margin: 0;
  font-size: 11px;
  color: #ff0000;
  font-weight: bold;
  position: absolute;
  bottom: -11px;
  /* right: 5%; */
  left: 0;
}
#boxx {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 10px;
  border-radius: 10px;
  padding: 1px;
  height: 81px;
  background-color:#F3F3FF;
  margin-top: 36px;
  color: rgb(161, 143, 143);

  width: 81px;
}
.mobile {
  background: #f7f7f7;
  padding: 0px 0;
}

#menu-bar #menu li.dropdown .dropdown-menu a{
  display:block;
  border-bottom:1px solid #f2f2f2;
}

#menu-bar #menu li.dropdown .dropdown-menu {
    border-radius: 2px;
    border-top:none !important;
    border-bottom: 4px solid #006;
    padding: 10px;
    width: 219px;
    font-size: 14px;
    max-height: 58vh;
    overflow: hidden;
    overflow-y: scroll;
    transform: none !important;
    top: 35px !important;
}

.sidepanel {
    bottom: 0 !important;
    border-bottom: 5px solid #006;
    width:0px;
}
.searchm {
    margin-top: -14px;
    padding-bottom: 0rem;
    margin-left: 10px;
}


#category-nav::-webkit-scrollbar, .navbar-collapse::-webkit-scrollbar {
    width: 5px;
    display: none;
    transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all;
}
.menu-link:hover #category-nav::-webkit-scrollbar, nav#side_nav:hover  .navbar-collapse::-webkit-scrollbar {
    display: block;
    transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all;
}

#category-nav::-webkit-scrollbar-thumb, .navbar-collapse::-webkit-scrollbar-thumb {-webkit-border-radius:2px;-o-border-radius:2px;border-radius:12px; background-color:#000066;transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all;} 

#category-nav::-webkit-scrollbar-thumb:hover, .navbar-collapse::-webkit-scrollbar-thumb:hover {-webkit-border-radius:2px;-o-border-radius:2px;border-radius:12px; background-color:#000066;background-image:-webkit-linear-gradient(90deg, transparent, #000066 50%, transparent, transparent);transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all;}
 .top{
   position: scroll !important;
 }

</style>
